import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    registerRequest,
    loginRequest,
    forgotPasswordRequest,
    resetPasswordRequest,
    logoutRequest,
} from './userActions';

function useAuth() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    // handle register request
    const handleRegisterRequest = useCallback((payload) => {
        dispatch(registerRequest(payload));
    }, []);

    // handle login request
    const handleLoginRequest = useCallback((payload) => {
        dispatch(loginRequest(payload));
    }, []);

    // handle forgotPassword request
    const handleForgotPasswordRequest = useCallback((payload) => {
        dispatch(forgotPasswordRequest(payload));
    }, []);

    // handle reset password request
    const handleResetPasswordRequest = useCallback((payload) => {
        dispatch(resetPasswordRequest(payload));
    }, []);

    // handle logout password request
    const handlelogoutRequest = useCallback(() => {
        dispatch(logoutRequest());
    }, []);

    return {
        user,
        registerRequest: handleRegisterRequest,
        loginRequest: handleLoginRequest,
        forgotPasswordRequest: handleForgotPasswordRequest,
        resetPasswordRequest: handleResetPasswordRequest,
        logoutRequest: handlelogoutRequest,
    };
}

export default useAuth;
